import "./App.css";
import HomeIndex from "./pages/Home/HomeIndex";
import { ThemeProvider } from "@mui/material";
import { darkTheme, lightTheme } from "./theme";
import { Route, Routes } from "react-router-dom";
import Listing from "./pages/Listing/ListingIndex";
import { SessionState } from "./features/session/session";
import { useAppSelector } from "./app/hooks";
import { useEffect } from "react";
import Properties from "./pages/Properties/PropertiesIndex";
import MapIndex from "./pages/Map/MapIndex";
import Contact from "./pages/Contact/ContactIndex";
import About from "./pages/About/AboutIndex";
import Services from "./pages/Services/ServicesIndex";

function App() {
   const session: SessionState = useAppSelector((state: any) => state.session);

   //const dispatch = useAppDispatch();
   const { darkMode } = session;
   useEffect(() => {
      // DM toggle
      return () => {};
   }, [darkMode]);
   return (
      <>
         <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
            <Routes>
               <Route path='' element={<HomeIndex />} />
               <Route path='/' element={<HomeIndex />} />
               <Route path='/listing/:id' element={<Listing />} />
               <Route path='/map' element={<MapIndex />} />
               <Route path='/properties/:sub' element={<Properties />} />
               <Route path='/properties/' element={<Properties />} />
               <Route path='/contact' element={<Contact />} />
               <Route path='/about' element={<About />} />
               <Route path='/services' element={<Services />} />
            </Routes>
         </ThemeProvider>
      </>
   );
}

export default App;
