import {
   Button,
   ButtonGroup,
   Chip,
   Grid,
   Paper,
   Tooltip,
   Typography,
} from "@mui/material";

import BathroomIcon from "@mui/icons-material/Bathroom";
import BedIcon from "@mui/icons-material/Bed";
import PhotoIcon from "@mui/icons-material/Photo";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import SliderImg from "../../components/SliderImg";
import { Listing } from "../../features/listings/listings";
import { PRIMARY_COLOR } from "../../global/constants";
import {
   generateAddressUrl,
   notZero,
   notZeroBrackets,
} from "../../global/utils";
import { SessionState } from "../../features/session/session";
import { useAppSelector } from "../../app/hooks";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const PropertiesListBox = (props: {
   row: Listing;
   session: SessionState;
   imgHeight?: number | string;
   imgWidth?: number | string;
}) => {
   let listing = props.row;
   const { imgHeight = 270, imgWidth = 450 } = props;
   const session = props.session;
   const ENG = session.lang === "en" ? true : false;

   const navigate = useNavigate();

   const price = listing.price_current
      ? new Intl.NumberFormat("en-US", {
           style: "currency",
           currency: "USD",
        }).format(Number(listing.price_current))
      : "";

   const handleShowListing = () => {
      const addressUrl = generateAddressUrl(listing);
      navigate(`/listing/${addressUrl}`);
   };

   const handleMapClick = (lat: number, lng: number) => {
      // Open a new window with Google Maps
      const newWindow: any = window.open(
         `https://www.google.com/maps?q=${lat},${lng}`,
         "_blank"
      );
      if (newWindow) {
         // Add a marker to the map
         newWindow.addEventListener("load", () => {
            const marker = newWindow.google.maps.Marker({
               position: { lat: lng },
               map: newWindow.map,
               title: "Marker",
            });
         });
      } else {
         // Handle popup blocker
         alert("Please allow popups to see the map.");
      }
   };

   let imagesArr = listing.images
      ? listing.images.includes(",")
         ? listing.images.split(",")
         : [listing.images]
      : ["noImage.png"];

   try {
      if (
         listing &&
         (!listing.unique_id ||
            listing.unique_id === "" ||
            listing.unique_id === undefined ||
            listing.unique_id === null)
      ) {
         listing.unique_id = "000000NA";
      }
   } catch (error) {
      console.log("Error in ListingBox.tsx: ", error);
   }

   const saleText = ENG ? "FOR SALE" : "EN VENTA";
   const rentalText = ENG ? "RENTAL" : "ALQUILER";

   return (
      <Paper
         sx={{
            p: 2,
            margin: "auto",
            flexGrow: 1,
            backgroundColor: (theme) =>
               theme.palette.mode === "dark" ? "#1A2027" : "#fff",
         }}
      >
         <Grid container spacing={2}>
            <Grid item xs={12} md={5}>
               <SliderImg
                  images={imagesArr}
                  width={imgWidth}
                  height={imgHeight}
               />
            </Grid>
            <Grid item xs={12} md={7} sm container>
               <Grid item xs container direction='column' spacing={2}>
                  <Grid item xs>
                     <div
                        style={{
                           display: "flex",
                           alignItems: "center",
                           paddingBottom: 8,
                        }}
                     >
                        <Typography
                           variant='h6'
                           component='span'
                           style={{ marginRight: 8 }}
                        >
                           {price}
                        </Typography>
                        <Typography
                           variant='body2'
                           color='text.secondary'
                           component={"span"}
                           style={{
                              backgroundColor:
                                 listing.price_current &&
                                 parseInt(String(listing.price_current)) > 5000
                                    ? "#F00"
                                    : "#b57610",
                              color: "#FFF",
                              padding: "4px",
                              marginRight: 8,
                           }}
                        >
                           {listing.price_current &&
                           parseInt(String(listing.price_current)) > 5000
                              ? saleText
                              : rentalText}
                        </Typography>
                        {listing.map_area}
                        {notZeroBrackets(listing.year_built)}
                        <Tooltip title='Location'>
                           <Button
                              style={{ marginTop: -10 }}
                              size='small'
                              onClick={() =>
                                 handleMapClick(
                                    parseFloat(listing.latitude),
                                    parseFloat(listing.longitude)
                                 )
                              }
                           >
                              <LocationOnIcon />
                           </Button>
                        </Tooltip>
                     </div>
                     <ButtonGroup sx={{ px: 1 }}>
                        <Tooltip title='Photos' arrow>
                           <Button
                              variant='outlined'
                              startIcon={<PhotoIcon />}
                              size='small'
                           >
                              {listing.listing_photo_count}
                           </Button>
                        </Tooltip>
                        <Tooltip title='Bathrooms' arrow>
                           <Button
                              variant='outlined'
                              startIcon={<BathroomIcon />}
                              size='small'
                           >
                              {listing.bathrooms}
                           </Button>
                        </Tooltip>
                        <Tooltip title='Bedrooms' arrow>
                           <Button
                              variant='outlined'
                              startIcon={<BedIcon />}
                              size='small'
                           >
                              {listing.bedrooms}
                           </Button>
                        </Tooltip>
                        <Tooltip title='M2' arrow>
                           <Button variant='outlined' size='small'>
                              {listing.sqft_total}m<sup>2</sup>
                           </Button>
                        </Tooltip>
                        <Tooltip title='Lot Sq/ft' arrow>
                           <Button variant='outlined' size='small'>
                              {listing.lot_sqft} m<sup>2</sup>
                           </Button>
                        </Tooltip>
                     </ButtonGroup>
                     <div style={{ marginTop: 8 }}>
                        <Typography
                           variant='body2'
                           gutterBottom
                           component={"span"}
                        >
                           {notZero(listing.street_number)}{" "}
                           {notZero(listing.street_name)}{" "}
                           {notZero(listing.district)}{" "}
                        </Typography>
                        <Typography
                           variant='body2'
                           color='text.secondary'
                           component={"span"}
                        >
                           {notZero(listing.directions)}
                           <div
                              style={{
                                 width: "100%",
                                 height: "90px",
                                 overflowY: "scroll",
                              }}
                           >
                              {ENG ? listing.remarks : listing.remarks_es}
                           </div>
                        </Typography>
                     </div>
                  </Grid>
                  <Grid item>
                     <Typography
                        sx={{ cursor: "pointer" }}
                        variant='body2'
                        component={"span"}
                     >
                        {listing.property_name}{" "}
                        <Chip label={listing.date_listed} size='small' />
                        <Button
                           size='small'
                           variant='outlined'
                           style={{ marginLeft: 10 }}
                           onClick={() => handleShowListing()}
                        >
                           {ENG ? "View Listing" : "Ver Propiedad"}
                        </Button>
                     </Typography>
                  </Grid>
               </Grid>
            </Grid>
         </Grid>
      </Paper>
   );
};
