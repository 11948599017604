import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { LinksState } from "./links";

const initialState: LinksState = {
   arr: [],
   init: false,
};

export const linksSlice = createSlice({
   name: "links",
   initialState,
   // The `reducers` field lets us define reducers and generate associated actions
   reducers: {
      setLinks: (state, action: PayloadAction<LinksState>) => {
         // Clear the state before setting new links
         state.arr.splice(0, state.arr.length);
         // Push each new link into the state
         action.payload.arr.forEach((link) => {
            state.arr.push(link);
         });
         // Set the init flag to true
         state.init = true;
      },
      updateSingleLink: (
         state: any,
         action: PayloadAction<{
            id: string | number;
            updateFields: string[];
            updateValues: string[];
         }>
      ) => {
         // given that you are passed the id, updateFields[] and updateValues[]
         // find the link with the id and update the fields
         const { id, updateFields, updateValues } = action.payload;
         const index = state.arr.findIndex((link: any) => link.id === id);
         if (index !== -1) {
            updateFields.forEach((field: any, i: any) => {
               state.arr[index][field] = updateValues[i];
            });
         }
      },
      addSingleLink: (state, action: PayloadAction<LinksState>) => {
         // Add a single link
         state.arr.push(action.payload.arr[0]);
      },
      clearLinks: (state) => {
         // Clear the state
         state.arr.splice(0, state.arr.length);
         state.init = false;
      },
   },
});

export const { setLinks, clearLinks, updateSingleLink, addSingleLink } =
   linksSlice.actions;
export const selectLinks = (state: any) => state.links;

export default linksSlice.reducer;
