import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import rootReducer from "./reducers";

// Load state from localStorage
const loadState = (): any => {
   try {
      const serializedState = localStorage.getItem("reduxState");
      if (serializedState === null) {
         return undefined;
      }
      return JSON.parse(serializedState);
   } catch (error) {
      console.error("Error loading state from localStorage:", error);
      return undefined;
   }
};

// Save state to localStorage
const saveState = (state: RootState): void => {
   try {
      const serializedState = JSON.stringify(state);
      localStorage.setItem("reduxState", serializedState);
   } catch (error) {
      console.error("Error saving state to localStorage:", error);
   }
};

// Load initial state from localStorage
const initialState = loadState();

export const store = configureStore({
   reducer: rootReducer,
   preloadedState: initialState, // Initialize store with loaded state
   // middleware: () => [],
});

// Save state to localStorage whenever it changes
store.subscribe(() => {
   saveState(store.getState());
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
   ReturnType,
   RootState,
   unknown,
   Action<string>
>;
