import React from "react";
import { Listing } from "../../../features/listings/listings";
import { SessionState } from "../../../features/session/session";
import { Grid, useMediaQuery, useTheme } from "@mui/material";
import { formatCurrency } from "../../../global/utils";
import { format } from "path";

interface DetailsProps {
   listing: Listing;
   session: SessionState;
}

export default function ListingDescription(props: DetailsProps): JSX.Element {
   const { listing, session } = props;
   const theme = useTheme();
   const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
   const ENG = session.lang === "en" ? true : false;

   const PropertyDetails = (props: { listing: Listing }) => {
      const { listing } = props;
      const theme = useTheme();
      const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
      const attributes = [
         // { name: "MLS ID", value: listing.id },
         {
            name: ENG ? "Property Type" : "Tipo de propiedad",
            value: listing.property_type,
         },
         {
            name: ENG ? "Listing Type" : "Tipo de listado",
            value: listing.price_current ? "For Sale" : "For Rent",
         },
         {
            name: ENG ? "Building Name" : "Nombre del edificio",
            value: listing.property_name,
         },
         {
            name: ENG ? "Price" : "Precio",
            value: listing.price_current
               ? formatCurrency(Number(listing.price_current))
               : formatCurrency(Number(listing.price_current_rent)),
         },
         {
            name: ENG ? "Monthly Fee US$" : "Cuota mensual US$",
            value: formatCurrency(parseFloat(listing.monthly_assessment)),
         },
         {
            name: ENG ? "Year Built" : "Año de construcción",
            value: listing.year_built,
         },
         {
            name: ENG ? "Building Size" : "Tamaño del edificio",
            value: listing.sqft_total + "m²" || listing.lot_sqft + "m²",
         },
         { name: ENG ? "Bedrooms" : "Dormitorios", value: listing.bedrooms },
         { name: ENG ? "Bathrooms" : "Baños", value: listing.bathrooms },
         {
            name: ENG ? "Parking Spaces" : "Plazas de aparcamiento",
            value: listing.parking_spaces,
         },
         {
            name: ENG ? "Date Listed" : "Fecha de listado",
            value: listing.date_listed,
         },
         {
            name: ENG ? "Status" : "Estado",
            value: listing.status,
         },
      ];

      return (
         <Grid container spacing={2}>
            {attributes &&
               attributes[0] &&
               attributes.map(
                  (attribute: any, index: number) =>
                     (!isMobile || index % 2 === 0) && (
                        <Grid item xs={12} sm={6} key={index}>
                           <strong>{attribute.name}:</strong>
                           <span
                              style={{
                                 color: session.darkMode ? "yellow" : "#000",
                                 marginLeft: 5,
                              }}
                           >
                              {attribute.value}
                           </span>
                        </Grid>
                     )
               )}
         </Grid>
      );
   };

   return <PropertyDetails listing={listing} />;
}
