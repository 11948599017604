import Layout from "../../components/Layout";
import LayoutInnerEnclosed from "../../components/LayoutInnerEnclosed";
import { ListingsState } from "../../features/listings/listings";
import { useAppSelector } from "../../app/hooks";
import React from "react";

export default function Services(): JSX.Element {
   return (
      <Layout>
         <LayoutInnerEnclosed>
            <h3>Our Services</h3>

            <section>
               <h5>Real Estate Buying</h5>
               <p>
                  At Parecsa Inc., we assist clients in finding their dream
                  homes or investment properties in Panama. Our experienced
                  agents are dedicated to understanding your needs and
                  preferences to find the best possible options. We guide you
                  through the entire buying process, ensuring a smooth and
                  stress-free experience.
               </p>
            </section>

            <section>
               <h5>Real Estate Selling</h5>
               <p>
                  Selling your property with Parecsa Inc. ensures you receive
                  the best market value. We provide comprehensive market
                  analysis, professional photography, and strategic marketing
                  plans to attract potential buyers. Our team is committed to
                  making the selling process quick and efficient.
               </p>
            </section>

            <section>
               <h5>Real Estate Renting</h5>
               <p>
                  Whether you are looking to rent a property or need help
                  renting out your property, Parecsa Inc. has you covered. We
                  offer a wide range of rental properties and provide tenant
                  screening, lease agreements, and property maintenance
                  services. Our goal is to match tenants with their ideal rental
                  homes.
               </p>
            </section>

            <section>
               <h5>Property Management</h5>
               <p>
                  Parecsa Inc. offers comprehensive property management services
                  to ensure your property is well-maintained and profitable.
                  From tenant management to regular maintenance and financial
                  reporting, we handle all aspects of property management. Our
                  services are designed to give you peace of mind and maximize
                  your investment.
               </p>
            </section>

            <section>
               <h5>Construction Services</h5>
               <p>
                  Our construction services at Parecsa Inc. cater to both
                  residential and commercial projects. We manage everything from
                  initial planning and design to construction and final
                  inspection. With a focus on quality and timely completion, we
                  bring your construction vision to life.
               </p>
            </section>
         </LayoutInnerEnclosed>
      </Layout>
   );
}
