import Layout from "../../components/Layout";
import LayoutInnerEnclosed from "../../components/LayoutInnerEnclosed";
import { ListingsState } from "../../features/listings/listings";
import { useAppSelector } from "../../app/hooks";
import React from "react";

export default function Contact(): JSX.Element {
   return (
      <Layout>
         <LayoutInnerEnclosed>
            <h3>Contact</h3>
            <p>
               Parec, Inc., <br />
               Calle 66 Este San Francisco, <br />
               PH Plaza Pacifica, <br />
               Panama City, <br />
               Panama
            </p>
            <p>
               Email: <a href='mailto:info@parecsa.com'>info@parecsa.com</a>
            </p>
            <p>
               Telephone: <a href='tel:+5073004040'>+507-300-4040</a>
            </p>
            <p>
               Our office hours are typically 9AM-3PM on Monday to Friday unless
               there is a national holiday. Please stop in any time or call us
               in advance to set up a consultation.
            </p>
            <p>
               We are happy to serve the needs of clients throughout Panama City
               and the interior of the country.
            </p>
         </LayoutInnerEnclosed>
      </Layout>
   );
}
