import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { OfficesState } from "./offices";

const initialState: OfficesState = {
   arr: [],
   init: false,
};

export const officesSlice = createSlice({
   name: "offices",
   initialState,
   // The `reducers` field lets us define reducers and generate associated actions
   reducers: {
      setOffices: (state, action: PayloadAction<OfficesState>) => {
         // Clear the state before setting new offices
         state.arr.splice(0, state.arr.length);
         // Push each new office into the state
         action.payload.arr.forEach((office) => {
            state.arr.push(office);
         });
         // Set the init flag to true
         state.init = true;
      },
      updateSingleOffice: (
         state: any,
         action: PayloadAction<{
            id: string | number;
            updateFields: string[];
            updateValues: string[];
         }>
      ) => {
         // given that you are passed the id, updateFields[] and updateValues[]
         // find the office with the id and update the fields
         const { id, updateFields, updateValues } = action.payload;
         const index = state.arr.findIndex((office: any) => office.id === id);
         if (index !== -1) {
            updateFields.forEach((field: any, i: any) => {
               state.arr[index][field] = updateValues[i];
            });
         }
      },
      addSingleOffice: (state, action: PayloadAction<OfficesState>) => {
         // Add a single office
         state.arr.push(action.payload.arr[0]);
      },
      clearOffices: (state) => {
         // Clear the state
         state.arr.splice(0, state.arr.length);
         state.init = false;
      },
   },
});

export const { setOffices, clearOffices, updateSingleOffice, addSingleOffice } =
   officesSlice.actions;
export const selectOffices = (state: RootState) => state.offices;

export default officesSlice.reducer;
