import { API_URL } from "./constants";
import { apiPost } from "./ApiRequest";
import { Listing, ListingsState } from "../features/listings/listings";

export const sqlPrep = ({ s }: { s: any }): string => {
   s = s.replace(/'/gi, "`");
   s = s.replace(/"/gi, '\\"');
   s = s.replace(/</g, "&lt;"); //for <
   s = s.replace(/>/g, "&gt;"); //for >

   return s;
};

export const rand = (): string => {
   const length = 20;
   let s = "";
   for (let i = 0; i < 20; i++) {
      s +=
         "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789".charAt(
            (Math.random() * 62) | 0
         );
   }
   return s;
};

export const msg = (msg: string | any, severity: string) => {
   return {
      msg,
      severity,
   };
};

export const dia = (
   open: boolean,
   title: string,
   content: string,
   params: any
) => {
   return {
      open,
      title,
      content,
      params,
   };
};

export const fetcher = async (
   obj: any,
   setFunction: any,
   postUrl: string,
   token: any,
   dis: any
) => {
   if (!obj.init) {
      const res = await apiPost(postUrl, { token });
      if (!res.data.err && !obj.init) {
         dis(setFunction({ arr: res.data.data, init: true }));
      }
   }
};

export const buildData = (labels: string[], datas: number[]) => {
   return {
      labels,
      datasets: [
         {
            label: "# of Votes",
            data: datas,
            backgroundColor: [
               "rgba(255, 99, 132, 0.2)",
               "rgba(54, 162, 235, 0.2)",
               "rgba(255, 206, 86, 0.2)",
               "rgba(75, 192, 192, 0.2)",
               "rgba(153, 102, 255, 0.2)",
               "rgba(255, 159, 64, 0.2)",
            ],
            borderColor: [
               "rgba(255, 99, 132, 1)",
               "rgba(54, 162, 235, 1)",
               "rgba(255, 206, 86, 1)",
               "rgba(75, 192, 192, 1)",
               "rgba(153, 102, 255, 1)",
               "rgba(255, 159, 64, 1)",
            ],
            borderWidth: 1,
         },
      ],
   };
};

export const txtValid = (txt: string | any) => {
   try {
      return txt.toString().length > 1 ? true : false;
   } catch {
      return false;
   }
};

interface NestedObject {
   [key: string]: any | NestedObject | NestedObject[];
}

export function findDataArray(obj: NestedObject): any[] | null {
   // Iterate over each element in the object until you find an array named "data" and return it
   for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
         const element = obj[key];
         if (Array.isArray(element) && key === "arr") {
            return element;
         } else if (typeof element === "object" && element !== null) {
            const dataArr = findDataArray(element);
            if (dataArr) {
               return dataArr;
            }
         }
      }
   }

   // If "data" array was not found, return null
   return null;
}

export function epochToDate(epoch: any) {
   const date = new Date(epoch);
   const year = date.getFullYear();
   const month = String(date.getMonth() + 1).padStart(2, "0");
   const day = String(date.getDate()).padStart(2, "0");
   return `${year}/${month}/${day}`;
}

export function returnDateNow() {
   const currentDate = new Date();

   // Get the components of the date
   const year = currentDate.getFullYear();
   const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Months are zero-based
   const day = String(currentDate.getDate()).padStart(2, "0");
   const hours = String(currentDate.getHours()).padStart(2, "0");
   const minutes = String(currentDate.getMinutes()).padStart(2, "0");
   const seconds = String(currentDate.getSeconds()).padStart(2, "0");

   // Create the formatted date string
   const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

   return formattedDate;
}

export function returnDateToday() {
   const currentDate = new Date();

   // Get the components of the date
   const year = currentDate.getFullYear();
   const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Months are zero-based
   const day = String(currentDate.getDate()).padStart(2, "0");

   // Create the formatted date string
   const formattedDate = `${year}-${month}-${day}`;

   return formattedDate;
}

export const formatUrl = (url: string | undefined) => {
   return !url
      ? "#"
      : url.startsWith("http://") || url.startsWith("https://")
      ? url
      : `https://${url}`;
};

export const formatDisplayUrl = (url: string | undefined) => {
   // https:// from url or http:// from url or empty string
   // then remove www. if exists
   // then return the url
   return !url
      ? ""
      : url.startsWith("https://")
      ? url.replace("https://www.", "")
      : url.startsWith("http://")
      ? url.replace("http://www.", "")
      : url.startsWith("www.")
      ? url.replace("www.", "")
      : url;
};

export const fetchData = async (tableName: any, token: string) => {
   const limit = tableName === "images" ? 95000 : 2000;
   const res = await apiPost(API_URL + "/crud/search/all", {
      token,
      tableName,
      limit,
   });

   if (!res.data.err) {
      return res.data.data;
   } else {
      throw new Error(`Error loading ${tableName}`);
   }
};

export const fetchDataAnd = async (
   tableName: any,
   token: string,
   onlyFields: string,
   searchFields: string,
   search: string | number
) => {
   const limit = tableName === "images" ? 95000 : 2000;
   const res = await apiPost(API_URL + "/crud/search/and", {
      token,
      tableName,
      searchFields,
      search,
      onlyFields,
      limit,
   });

   if (!res.data.err) {
      return res.data.data;
   } else {
      throw new Error(`Error loading ${tableName}`);
   }
};

export const formatCurrency = (value: number) => {
   return `$${value.toLocaleString()}`;
};

export function generateAddressUrl(listing: Listing) {
   const { street_number, street_name, subdivision, region, id } = listing;
   const urlBase = `${street_number}-${street_name}-${subdivision}-${region}-MLS${id}`;
   const addressUrl = urlBase.replace(/[\s,]/g, "-").toLowerCase();
   return addressUrl;
}

export const getAddress = (listing: Listing) => {
   let address = "";
   if (
      listing.street_number !== undefined &&
      parseInt(listing.street_number) !== 0
   )
      address += `${listing.street_number} `;
   if (listing.street_name !== undefined) address += listing.street_name;
   if (listing.subdivision !== undefined) address += `, ${listing.subdivision}`;
   if (listing.region !== undefined) address += `, ${listing.region}`;
   return address;
};

export const notZero = (val: any) => {
   return val === 0 ? "" : val;
};

export const notZeroBrackets = (val: any) => {
   return val !== 0 ? `(${val})` : "";
};
