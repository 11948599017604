import React from "react";
import { Button, Chip, Grid, TextField, Tooltip } from "@mui/material";
import { SessionState } from "../../features/session/session";
import { useAppSelector } from "../../app/hooks";
import { ContactForm } from "../../components/ContactForm";
import DisplayListingTopLeft from "./DisplayListingTopLeft";
import DisplayListingTopRight from "./DisplayListingTopRight";
import ListingBottom from "./ListingBottom";
import SliderImg from "../../components/SliderImg";

interface DisplayListingProps {
   listing: any;
}

export default function DisplayListing(props: DisplayListingProps) {
   const { listing } = props;
   const imagesArr = listing.images
      ? listing.images.includes(",")
         ? listing.images.split(",")
         : [listing.images]
      : ["noImage.png"];
   console.log(imagesArr);
   const session: SessionState = useAppSelector((state) => state.session);

   return (
      <>
         <form>
            <Grid container spacing={2}>
               <Grid item xs={12} md={8}>
                  <DisplayListingTopLeft listing={listing} session={session} />
               </Grid>
               <Grid item xs={12} md={4}>
                  <DisplayListingTopRight session={session} listing={listing} />
               </Grid>
               <Grid item xs={12} sm={8}>
                  <SliderImg
                     images={imagesArr}
                     width={"100%"}
                     height={"vw.5"}
                  />
               </Grid>
               <Grid item xs={12} sm={4}>
                  <ContactForm
                     defaultMsg={
                        session.lang === "en"
                           ? "I would like more information on: " +
                             listing.web_title
                           : "Hola estoy interesado y quiero más información de: " +
                             listing.web_title_es
                     }
                  />
               </Grid>
               <Grid item xs={12} lg={12}>
                  <ListingBottom listing={listing} session={session} />
               </Grid>
            </Grid>
         </form>
      </>
   );
}
