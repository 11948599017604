import React from "react";
import { LAYOUT_WIDTH } from "../global/constants";

export default function LayoutInnerEnclosed(props: {
   children: any;
}): JSX.Element {
   const { children } = props;
   return (
      <div
         style={{
            padding: 10,
            maxWidth: LAYOUT_WIDTH,
            minHeight: 600,
            margin: "0 auto", // Center horizontally using auto margin
         }}
      >
         {children}
      </div>
   );
}
