import Layout from "../../components/Layout";
import LayoutInnerEnclosed from "../../components/LayoutInnerEnclosed";
import { ListingsState } from "../../features/listings/listings";
import { useAppSelector } from "../../app/hooks";
import React from "react";

export default function About(): JSX.Element {
   return (
      <Layout>
         <LayoutInnerEnclosed>
            <h3>About</h3>
            Panama Real Estate Consultants (PAREC, Inc) es una empresa panameña
            inscrita en el Registro Público de Panamá. Contamos con más de 45
            años de experiencia en el mercado panameño.
            <br />
            <br />
            El Grupo Moses siempre ha trabajado con integridad, transparencia y
            asesoramiento honesto, y esos son los principios que exigimos a
            nuestro personal. Con esto en mente, nuestro principal objetivo es
            ayudar a nuestros clientes a obtener el MEJOR Servicio de
            Consultoría Inmobiliaria y de Construcción. Lo guiaremos en su
            búsqueda para encontrar las mejores oportunidades de inversión o
            encontrar los mejores bienes raíces en Panamá.
            <br />
            <br />
            Nuestro personal calificado lo guiará en todos los trámites
            necesarios para realizar su inversión. Ofrecemos una amplia gama de
            propiedades en la Ciudad de Panamá, Playa, Montañas, Islas o
            Propiedades en el Campo.
            <br />
            <br />
            Para saber más sobre nuestro personal y nuestra empresa, contáctenos
            para que podamos ofrecerle el servicio que se merece. Hemos hecho de
            Panama Real Estate Consultants un verdadero destino de “ventanilla
            única” para una gama completa de productos y servicios
            inmobiliarios. Todo nuestro personal es totalmente bilingüe, por lo
            que lo guiaremos con orientación profesional y experimentada para
            hacer de sus inversiones una transacción altamente segura.
         </LayoutInnerEnclosed>
      </Layout>
   );
}
