import React, { useState, useEffect, useCallback } from "react";
import {
   GoogleMap,
   useLoadScript,
   Marker,
   InfoWindow,
   InfoWindowF,
} from "@react-google-maps/api";
import {
   GOOGLE_MAPS_API_KEY,
   MAPS_CENTER_CANAL_LATITUDE,
   MAPS_CENTER_CANAL_LONGITUDE,
   MAPS_ZOOM,
} from "../../global/constants";
import { useSelector } from "react-redux";
import { RootState } from "../../app/store";
import { PropertiesListBoxMap } from "./PropertiesListBoxMap";

interface GoogleMapPointsProps {
   checkNewListings: (listings: any[]) => void;
   mapBounds: google.maps.LatLngBounds | null;
   setMapBounds: (bounds: google.maps.LatLngBounds) => void;
}

export const GoogleMapPoints: React.FC<GoogleMapPointsProps> = (props) => {
   const { checkNewListings, mapBounds, setMapBounds } = props;
   const session = useSelector((state: RootState) => state.session);
   const listings = useSelector((state: RootState) => state.listings.arr);
   const [map, setMap] = useState<google.maps.Map | null>(null);
   const [center, setCenter] = useState({
      lat: MAPS_CENTER_CANAL_LATITUDE,
      lng: MAPS_CENTER_CANAL_LONGITUDE,
   });
   const [selectedListing, setSelectedListing] = useState<any | null>(null);

   const { isLoaded, loadError } = useLoadScript({
      googleMapsApiKey: GOOGLE_MAPS_API_KEY,
   });

   const updateMapListings = useCallback(() => {
      if (map && mapBounds) {
         const filteredListings = listings.filter((listing) =>
            mapBounds.contains(
               new google.maps.LatLng(
                  parseFloat(listing.latitude),
                  parseFloat(listing.longitude)
               )
            )
         );

         checkNewListings(filteredListings);
      }
   }, [map, mapBounds, listings, checkNewListings]);

   useEffect(() => {
      if (map) {
         const bounds = map.getBounds();
         if (bounds) {
            setMapBounds(bounds);
         }
      }
   }, [map, setMapBounds]);

   useEffect(() => {
      updateMapListings();
   }, [updateMapListings, mapBounds]);

   if (loadError) return <div>Error loading maps</div>;
   if (!isLoaded) return <div>Loading Maps</div>;

   const handleMapLoad = (mapInstance: google.maps.Map) => {
      setMap(mapInstance);
      const bounds = mapInstance.getBounds();
      if (bounds) {
         setMapBounds(bounds);
      }
   };

   const handleMapIdle = () => {
      if (map) {
         const bounds = map.getBounds();
         if (bounds) {
            setMapBounds(bounds);
         }
      }
   };

   const isWithinBounds = (lat: number, lng: number) => {
      return mapBounds?.contains(new google.maps.LatLng(lat, lng));
   };

   return (
      <div
         style={{
            width: "100%",
            height: "65vh",
            display: "flex",
            flexDirection: "column",
            marginTop: 20,
         }}
      >
         <div style={{ flex: 1 }}>
            <GoogleMap
               zoom={MAPS_ZOOM}
               center={center}
               onLoad={handleMapLoad}
               mapContainerStyle={{ width: "100%", height: "100%" }}
               onIdle={handleMapIdle}
            >
               {listings &&
                  listings.map(
                     (listing, index) =>
                        isWithinBounds(
                           parseFloat(listing.latitude),
                           parseFloat(listing.longitude)
                        ) && (
                           <Marker
                              key={index}
                              position={{
                                 lat: parseFloat(listing.latitude),
                                 lng: parseFloat(listing.longitude),
                              }}
                              onClick={() => setSelectedListing(listing)}
                           />
                        )
                  )}

               {selectedListing && (
                  <InfoWindowF
                     key={selectedListing.id}
                     position={{
                        lat: parseFloat(selectedListing.latitude),
                        lng: parseFloat(selectedListing.longitude),
                     }}
                     onCloseClick={() => setSelectedListing(null)}
                  >
                     <div style={{ width: 290, height: 200 }}>
                        <PropertiesListBoxMap
                           row={selectedListing}
                           session={session}
                           imgHeight={180}
                           imgWidth={280}
                        />
                     </div>
                  </InfoWindowF>
               )}
            </GoogleMap>
         </div>
      </div>
   );
};
