import Layout from "../../components/Layout";
import LayoutInnerEnclosed from "../../components/LayoutInnerEnclosed";
import { ListingsState } from "../../features/listings/listings";
import { useAppSelector } from "../../app/hooks";
import { GoogleMapPoints } from "./GoogleMapPoints";
import React from "react";
import { Grid } from "@mui/material";
import PropertiesListMap from "./PropertiesListMap";

const mapStyles = {
   width: "50%",
   height: "100%",
};

export default function MapIndex(): JSX.Element {
   const listings: ListingsState = useAppSelector(
      (state: any) => state.listings
   );
   const session = useAppSelector((state: any) => state.session);
   const [mapListings, setMapListings] = React.useState<any>([]);
   const [mapBounds, setMapBounds] = React.useState<any>(null);
   console.log("MapIndex");
   const checkNewListings = (listings: any[]) => {
      if (listings.length !== mapListings.length) {
         console.log(listings);
         console.log(mapListings);
         setMapListings(listings);
         //} else {
         //console.log("---- listings are not changed");
      }
   };

   console.log(".................MapIndex");

   return (
      <Layout>
         <LayoutInnerEnclosed>
            <Grid container spacing={2} style={{ height: "60vh" }}>
               <Grid item xs={12} md={6}>
                  <GoogleMapPoints
                     checkNewListings={checkNewListings}
                     mapBounds={mapBounds}
                     setMapBounds={setMapBounds}
                  />
               </Grid>
               <Grid item xs={12} md={6} style={mapStyles}>
                  <PropertiesListMap
                     listingsArr={mapListings}
                     mapBounds={mapBounds}
                     imgHeight={180}
                     imgWidth={245}
                  />
               </Grid>
            </Grid>
         </LayoutInnerEnclosed>
      </Layout>
   );
}
