import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Keyboard, Pagination, Navigation } from "swiper/modules";
import { IMG_PATH } from "../global/constants";

interface SliderImgProps {
   images: any[];
   width?: number | string;
   height?: number | string;
}

export default function SliderImg(props: SliderImgProps) {
   const { images, height = 300, width = 400 } = props;
   console.log(props);

   return (
      <>
         <Swiper
            slidesPerView={1}
            // spaceBetween={30}
            keyboard={{
               enabled: true,
            }}
            style={{ width, height }}
            pagination={{
               type: "progressbar",
            }}
            navigation={true}
            modules={[Keyboard, Pagination, Navigation]}
         >
            {images[0] &&
               images.map((image) => (
                  <SwiperSlide key={image}>
                     <a
                        href={
                           image === "noImage.png"
                              ? "/assets/img/noImage.png"
                              : `${IMG_PATH}/${image}`
                        }
                        target='_blank'
                        rel='noopener noreferrer'
                     >
                        <img
                           src={
                              image === "noImage.png"
                                 ? "/assets/img/noImage.png"
                                 : IMG_PATH + "/" + image
                           }
                           loading='lazy'
                           // style={{
                           //    height: "475px",
                           //    width: "100%",
                           // }}
                           height={height}
                           width={width}
                        />
                     </a>
                  </SwiperSlide>
               ))}
         </Swiper>
      </>
   );
}
