import React from "react";
import { Listing } from "../../../features/listings/listings";
import { SessionState } from "../../../features/session/session";

interface DetailsProps {
   listing: Listing;
   session: SessionState;
}

export default function ListingDescription(props: DetailsProps): JSX.Element {
   const { listing, session } = props;
   const text = session.lang === "en" ? listing.remarks : listing.remarks_es;

   const words = text.split(/\s+/); // Split text into words (assuming words are separated by whitespace)

   const totalWords = words.length;
   const wordsPerPart = Math.ceil(totalWords / 3); // Calculate words per part, rounding up to ensure all words are distributed

   let textParts = [];
   try {
      for (let i = 0; i < 3; i++) {
         const startIndex = i * wordsPerPart;
         const endIndex = startIndex + wordsPerPart;
         const partWords = words.slice(startIndex, endIndex);
         textParts.push(partWords.join(" ")); // Join words back into a string for each part
      }
   } catch (e) {
      console.error(e);
      textParts = ["", "", ""]; // Return empty strings if an error occurs
   }

   const directions =
      session.lang === "en" ? listing.directions : listing.directions_es;

   return (
      <>
         <div className='containerText'>
            <div className='paragraph'>{textParts[0]}</div>
            <div className='paragraph'>{textParts[1]}</div>
            <div className='paragraph'>{textParts[2]}</div>
         </div>
         <div
            style={{
               color: session.darkMode ? "yellow" : "#000",
               paddingTop: 10,
               fontWeight: "bold",
            }}
         >
            {directions.toString().length > 1 ? directions : ""}
         </div>
      </>
   );
}
