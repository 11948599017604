import React from "react";
import { getAddress } from "../../global/utils";

interface DisplayListingTopLeftProps {
   listing: any;
   session: any;
}

export default function DisplayListingTopLeft(
   Props: DisplayListingTopLeftProps
): JSX.Element {
   const { listing, session } = Props;
   return (
      <>
         <div
            style={{
               fontSize: "1.5em",
               fontWeight: "600",
               marginBottom: 10,
            }}
         >
            {session.lang === "en" ? listing.web_title : listing.web_title_es}
         </div>

         {getAddress(listing)}
         <div
            style={{
               color: session.darkMode ? "#fff" : "#000",
               paddingTop: 10,
               fontSize: ".8em",
            }}
         >
            <img
               src='/assets/img/icons/double-bed.svg'
               alt='hero'
               width='23'
               height='23'
               style={{
                  filter: session.darkMode ? "invert(100%)" : "none",
                  marginBottom: -5,
               }}
            />
            {" " + listing.bedrooms}
            <img
               src='/assets/img/icons/bathroom.svg'
               alt='hero'
               width='23'
               height='23'
               style={{
                  filter: session.darkMode ? "invert(100%)" : "none",
                  marginBottom: -5,
                  marginLeft: 20,
               }}
            />
            {" " + listing.bathrooms}
            <img
               src='/assets/img/icons/square-ruler-tool.svg'
               alt='hero'
               width='23'
               height='23'
               style={{
                  filter: session.darkMode ? "invert(100%)" : "none",
                  marginBottom: -5,
                  marginLeft: 20,
               }}
            />
            {listing.sqft_total ? listing.sqft_total + " m²" : ""}{" "}
            {listing.lot_sqft
               ? " - (property)" + listing.lot_sqft + " m² "
               : ""}{" "}
            <img
               src='/assets/img/icons/garage.svg'
               alt='garage'
               width='23'
               height='23'
               style={{
                  filter: session.darkMode ? "invert(100%)" : "none",
                  marginBottom: -5,
                  marginLeft: 20,
               }}
            />
            {" " + listing.parking_spaces}
         </div>
      </>
   );
}
