import { useEffect, useState } from "react";

import {
   FormControl,
   InputLabel,
   MenuItem,
   Select,
   Table,
   TableBody,
   TableCell,
   TableContainer,
   TablePagination,
   TableRow,
   TextField,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { Listing, ListingsState } from "../../features/listings/listings";
import { useAppSelector } from "../../app/hooks";
import { PropertiesListBox } from "./PropertiesListBox";
import { SessionState } from "../../features/session/session";

export default function PropertiesListView(props: {
   listingsArr: Listing[];
   imgHeight?: number | string;
   imgWidth?: number | string;
}): JSX.Element {
   const session: SessionState = useAppSelector((state) => state.session);
   const listings: ListingsState = useAppSelector((state) => state.listings);
   const listingsArr =
      props.listingsArr && props.listingsArr.length > 0
         ? props.listingsArr
         : listings.arr;

   // Pagination state
   const [page, setPage] = useState(0);
   const [rowsPerPage, setRowsPerPage] = useState(3);

   // Change handler for pagination
   const handleChangePage = (event: any, newPage: any) => {
      event.preventDefault();
      setPage(newPage);
   };

   // Change handler for rows per page
   const handleChangeRowsPerPage = (event: any) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
   };
   const handleChangeSearch = (event: any) => {
      // setSearchTerm(event.target.value);
      setPage(0);
   };

   return (
      <>
         {/* Search input */}
         {/* <TextField
            size='small'
            label='Search Names'
            variant='outlined'
            value={searchTerm}
            onChange={(e) => handleChangeSearch(e)}
            style={{ marginBottom: "1rem" }}
         /> */}
         {/* <FormControl style={{ minWidth: 200, marginLeft: 10 }}>
            <InputLabel id='subdivision-label'>Subdivision</InputLabel>
            <Select
               labelId='subdivision-label'
               value={subdivision}
               onChange={(e) => (setSubdivision(e.target.value), setPage(0))}
            >
               {Array.from(
                  new Set(listings.arr.map((item) => item.subdivision))
               )
                  .sort((a, b) => a.localeCompare(b))
                  .map((subdivision) => (
                     <MenuItem key={subdivision} value={subdivision}>
                        {subdivision}
                     </MenuItem>
                  ))}
            </Select>
         </FormControl> */}
         {/* Table container */}
         <TableContainer>
            <Table>
               <TableBody>
                  {/* Render listings for the current page */}
                  {listingsArr &&
                     listingsArr
                        .slice(
                           page * rowsPerPage,
                           page * rowsPerPage + rowsPerPage
                        )
                        .map((row: any) => (
                           <TableRow key={row.id}>
                              <TableCell>
                                 <PropertiesListBox
                                    row={row}
                                    session={session}
                                    imgHeight={props.imgHeight}
                                    imgWidth={props.imgWidth}
                                 />
                              </TableCell>
                           </TableRow>
                        ))}
               </TableBody>
            </Table>
         </TableContainer>
         {/* Pagination controls */}
         <TablePagination
            rowsPerPageOptions={[3, 5, 10, 15]}
            component='div'
            count={listingsArr.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
         />
      </>
   );
}
