import * as React from "react";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import FavoriteIcon from "@mui/icons-material/Favorite";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import LightModeIcon from "@mui/icons-material/LightMode";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import { PRIMARY_COLOR } from "../global/constants";
import { FormControl, InputLabel, Select } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import {
   setLangSession,
   setPropertyStatus,
   setToggleDarkMode,
   setToggleLang,
} from "../features/session/sessionSlice";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const settings = ["Profile", "Account", "Dashboard", "Logout"];

function ResponsiveAppBar() {
   const dispatch = useAppDispatch();
   const navigate = useNavigate();
   const session = useAppSelector((state) => state.session);

   const pages =
      session.lang === "en"
         ? ["Services", "Buy", "Rent", "About", "Contact"]
         : ["Servicios", "Comprar", "Alquilar", "Acerca", "Contacto"];
   const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
      null
   );
   const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
      null
   );
   const [lang, setLang] = React.useState("en");
   const handleChangeLang = () => {
      try {
         dispatch(setToggleLang());
      } catch (error) {
         console.error("Error in ResponsiveAppBar.tsx: ", error);
      }
   };
   const handleClick = (page: string) => {
      console.log("Clicked: ", page);
      if (page === "Services") {
         navigate("/services");
      } else if (page === "Buy") {
         dispatch(setPropertyStatus(1 as number));
         navigate("/properties");
      } else if (page === "Rent") {
         dispatch(setPropertyStatus(2 as number));
         navigate("/properties");
      } else if (page === "About") {
         navigate("/about");
      } else if (page === "Contact") {
         navigate("/contact");
      }
   };

   const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) =>
      setAnchorElNav(event.currentTarget);
   const handleCloseNavMenu = () => setAnchorElNav(null);

   const handleDarkMode = () => {
      try {
         dispatch(setToggleDarkMode());
      } catch (error) {
         console.error("Error in ResponsiveAppBar.tsx: ", error);
      }
   };

   const handleGoHome = () => navigate("/");

   useEffect(() => {
      setLang(session.lang);
   }, [session.lang]);

   return (
      <div
         style={{
            width: "100%",
            borderBottom: session.darkMode
               ? "1px #000 solid"
               : "1px #bbb solid",
            position: "fixed",
            top: 0 /* Stick to the top of the viewport */,
            backgroundColor: session.darkMode
               ? "rgba(22, 22, 22, 0.8)"
               : "rgba(222, 222, 222, 0.8)",
            color: session.darkMode ? "white" : PRIMARY_COLOR,
            zIndex: 100,
         }}
      >
         {/* <AppBar position='sticky'> */}
         <Container maxWidth='xl'>
            <Toolbar disableGutters>
               <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
                  <IconButton
                     size='large'
                     aria-label='account of current user'
                     aria-controls='menu-appbar'
                     aria-haspopup='true'
                     onClick={handleOpenNavMenu}
                     color='inherit'
                  >
                     <MenuIcon />
                  </IconButton>
                  <Menu
                     id='menu-appbar'
                     anchorEl={anchorElNav}
                     anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                     }}
                     keepMounted
                     transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                     }}
                     open={Boolean(anchorElNav)}
                     onClose={handleCloseNavMenu}
                     sx={{
                        display: { xs: "block", md: "none" },
                     }}
                  >
                     {pages.map((page, index) => (
                        <MenuItem key={index} onClick={() => handleClick(page)}>
                           {page}
                        </MenuItem>
                     ))}
                  </Menu>
               </Box>

               <img
                  src={
                     session.darkMode
                        ? "/assets/img/Logo-sm-clear-white.png"
                        : "/assets/img/Logo-sm-clear.png"
                  }
                  alt='Parecs Logo'
                  width={80}
                  height={20}
                  onClick={handleGoHome}
                  style={{ cursor: "pointer" }}
               />

               <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
                  {pages.map((page, index) => (
                     <Button
                        key={index}
                        sx={{
                           my: 2,
                           color: session.darkMode ? "white" : PRIMARY_COLOR,
                           display: "block",
                        }}
                        onClick={() => handleClick(page)}
                     >
                        {page}
                     </Button>
                  ))}
               </Box>
               <Box sx={{ flexGrow: 0, p: 0 }}>
                  <Tooltip title='Favorites'>
                     <FavoriteIcon
                        sx={{
                           color: session.darkMode ? "white" : PRIMARY_COLOR,
                           cursor: "pointer",
                           p: 1,
                        }}
                     />
                  </Tooltip>

                  <Tooltip title='Darkmode'>
                     {session.darkMode ? (
                        <LightModeIcon
                           sx={{
                              color: session.darkMode ? "white" : PRIMARY_COLOR,
                              cursor: "pointer",
                              p: 1,
                           }}
                           onClick={() => handleDarkMode()}
                        />
                     ) : (
                        <DarkModeIcon
                           sx={{
                              color: session.darkMode ? "white" : PRIMARY_COLOR,
                              cursor: "pointer",
                              p: 1,
                           }}
                           onClick={() => handleDarkMode()}
                        />
                     )}
                  </Tooltip>

                  <FormControl>
                     <Select
                        value={lang}
                        size='small'
                        onChange={handleChangeLang}
                     >
                        <MenuItem value={"en"}>EN</MenuItem>
                        <MenuItem value={"es"}>ES</MenuItem>
                     </Select>
                  </FormControl>
               </Box>
            </Toolbar>
         </Container>
         {/* </AppBar> */}
      </div>
   );
}
export default ResponsiveAppBar;
