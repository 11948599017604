import React, { ReactNode } from "react";
import Nav from "./Nav"; // Import your Nav component
import Footer from "./Footer"; // Import your Footer component
import { SessionState } from "../features/session/session";
import { useAppSelector } from "../app/hooks";
import { PRIMARY_COLOR } from "../global/constants";

interface LayoutProps {
   children: ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
   const session: SessionState = useAppSelector((state: any) => state.session);
   return (
      <div
         style={{
            display: "flex",
            flexDirection: "column",
            minHeight: "100vh",
            backgroundColor: session.darkMode ? "#222" : "#f4f4f4",
            color: session.darkMode ? "#fff" : PRIMARY_COLOR,
         }}
      >
         <Nav />
         <div style={{ flex: 1, overflowY: "auto", marginBottom: 10 }}>
            <div style={{ marginTop: 80 }}>{children}</div>
         </div>
         <Footer />
      </div>
   );
};

export default Layout;
