import React, { useEffect } from "react";
import { SessionState } from "../../features/session/session";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
   Button,
   FormControl,
   Grid,
   InputLabel,
   MenuItem,
   Select,
   Slider,
   Typography,
} from "@mui/material";
import { formatCurrency } from "../../global/utils";
import {
   setBaths,
   setPriceRange,
   setPropertyStatus,
   setPropertyType,
   setRooms,
   setSizeRange,
} from "../../features/session/sessionSlice";
import { useNavigate } from "react-router-dom";

export default function HeroRight(): JSX.Element {
   const session: SessionState = useAppSelector((state: any) => state.session);
   const navigate = useNavigate();
   const dispatch = useAppDispatch();
   const {
      priceRange,
      sizeRange,
      rooms,
      baths,
      region,
      propertyStatus,
      propertyType,
   } = session;
   const [type, setType] = React.useState<number>(0);
   const [propType, setPropType] = React.useState<number>(0);
   const handleChangeType = (event: any) =>
      setType(event.target.value as number);
   const handleChangePropType = (event: any) =>
      setPropType(event.target.value as number);
   const handlePriceChage = (event: any) =>
      dispatch(setPriceRange(event.target.value as number[]));
   const handleSizeChange = (event: any) =>
      dispatch(setSizeRange(event.target.value as number[]));

   const getLabelType = () => (session.lang === "en" ? "Bathrooms" : "Baños");
   const getLabelRooms = () =>
      session.lang === "en" ? "Rooms" : "Habitaciones";

   useEffect(() => {
      //console.log(session);
   }, [session]);
   return (
      <div
         style={{
            width: 370,
            backgroundColor: "rgba(222,222,222, 0.6)",
            borderRadius: 4,
            padding: 20,
            margin: "0 auto", // Center horizontally
         }}
      >
         <div style={{ height: 20 }}></div>
         <FormControl fullWidth>
            <InputLabel id='propertyStatus'>
               {session.lang === "en"
                  ? "Property Status"
                  : "Estado de la propiedad"}
            </InputLabel>
            <Select
               size='small'
               value={propertyStatus}
               label={
                  session.lang === "en"
                     ? "Property Status"
                     : "Estado de la propiedad"
               }
               onChange={(e) =>
                  dispatch(setPropertyStatus(e.target.value as number))
               }
            >
               <MenuItem value={0}>
                  {session.lang === "en" ? "All" : "Todo"}
               </MenuItem>
               <MenuItem value={1}>
                  {session.lang === "en" ? "For Sale" : "En venta"}
               </MenuItem>
               <MenuItem value={2}>
                  {session.lang === "en" ? "For Rent" : "En renta"}
               </MenuItem>
            </Select>
         </FormControl>
         <div style={{ height: 20 }}></div>
         <FormControl fullWidth>
            <InputLabel id='propertyType'>
               {session.lang === "en" ? "Property Type" : "Tipo de listado"}
            </InputLabel>
            <Select
               size='small'
               value={propertyType}
               label={
                  session.lang === "en" ? "Property Type" : "Tipo de listado"
               }
               onChange={(e) =>
                  dispatch(setPropertyType(e.target.value as number))
               }
            >
               <MenuItem value={0}>
                  {session.lang === "en" ? "All" : "Todo"}
               </MenuItem>
               <MenuItem value={1}>
                  {session.lang === "en" ? "Apartment" : "Apartamento"}
               </MenuItem>
               <MenuItem value={2}>
                  {session.lang === "en" ? "House" : "Casa"}
               </MenuItem>
               <MenuItem value={3}>
                  {session.lang === "en" ? "Estate" : "Finca"}
               </MenuItem>
               <MenuItem value={4}>
                  {session.lang === "en" ? "Land" : "Terreno"}
               </MenuItem>
            </Select>
         </FormControl>
         <div style={{ height: 20 }}></div>
         <Grid container spacing={2}>
            <Grid item xs={6}>
               <FormControl fullWidth>
                  <InputLabel id='rooms'>{getLabelRooms()}</InputLabel>
                  <Select
                     size='small'
                     value={rooms}
                     label={getLabelRooms()}
                     onChange={(e) =>
                        dispatch(setRooms(e.target.value as number))
                     }
                  >
                     <MenuItem value={0}>All / Todo</MenuItem>
                     <MenuItem value={1}>1+</MenuItem>
                     <MenuItem value={2}>2+</MenuItem>
                     <MenuItem value={3}>3+</MenuItem>
                     <MenuItem value={4}>4+</MenuItem>
                     <MenuItem value={5}>5+</MenuItem>
                  </Select>
               </FormControl>
            </Grid>
            <Grid item xs={6}>
               <FormControl fullWidth>
                  <InputLabel id='bathrooms'>{getLabelType()}</InputLabel>
                  <Select
                     size='small'
                     value={baths}
                     label={getLabelType()}
                     onChange={(e) =>
                        dispatch(setBaths(e.target.value as number))
                     }
                  >
                     <MenuItem value={0}>All/Todos</MenuItem>
                     <MenuItem value={1}>1+</MenuItem>
                     <MenuItem value={2}>2+</MenuItem>
                     <MenuItem value={3}>3+</MenuItem>
                     <MenuItem value={4}>4+</MenuItem>
                  </Select>
               </FormControl>
            </Grid>
         </Grid>
         <div style={{ height: 20 }}></div>
         <Slider
            value={priceRange}
            onChange={handlePriceChage}
            valueLabelDisplay='auto'
            aria-labelledby='range-slider'
            step={500} // Increment by 500
            max={1200000}
            min={0}
            valueLabelFormat={(value) => formatCurrency(value)}
         />
         <Typography variant='body2'>
            {session.lang === "en" ? "Price Range" : "Rango de precios"} Min:{" "}
            {formatCurrency(priceRange[0])} - Max:
            {formatCurrency(priceRange[1])}
            {priceRange[1] === 1200000 ? "(+)" : ""}
         </Typography>
         <div style={{ height: 20 }}></div>
         <Slider
            value={sizeRange}
            onChange={handleSizeChange}
            valueLabelDisplay='auto'
            aria-labelledby='range-slider'
            step={50} // Increment by 500
            max={500}
            min={0}
            valueLabelFormat={(value) => value}
         />
         <Typography variant='body2'>
            {session.lang === "en" ? "Area" : "Superficie"} ft² Min:
            {sizeRange[0]} - Max:
            {sizeRange[1]}
            {sizeRange[1] === 500 ? "(+)" : ""}
         </Typography>
         <div style={{ height: 20 }}></div>
         <Button variant='contained' fullWidth onClick={() => navigate("/map")}>
            {session.lang === "en" ? "Search Map" : "Buscar mapa"}
         </Button>
         <div style={{ height: 20 }}></div>
         <Button
            variant='contained'
            fullWidth
            onClick={() => navigate("/properties")}
         >
            {session.lang === "en" ? "Grid View" : "Vista en cuadrícula"}
         </Button>
      </div>
   );
}
