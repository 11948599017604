import { combineReducers } from "@reduxjs/toolkit";
import agentsReducer from "../features/agents/agentsSlice";
import companiesReducer from "../features/companies/companiesSlice";
import dialogReducer from "../features/dialog/dialogSlice";
import sessionReducer from "../features/session/sessionSlice";
import snackbarReducer from "../features/snackbar/snackbarSlice";
import linksReducer from "../features/links/linksSlice";
import listingsReducer from "../features/listings/listingsSlice";
import officesReducer from "../features/offices/officesSlice";

const rootReducer = combineReducers({
   agents: agentsReducer,
   dialog: dialogReducer,
   session: sessionReducer,
   snackbar: snackbarReducer,
   companies: companiesReducer,
   links: linksReducer,
   listings: listingsReducer,
   offices: officesReducer,
});

export default rootReducer;
