import { Box, Button } from "@mui/material";
import { Session } from "inspector";
import React from "react";
import { SessionState } from "../features/session/session";
import CachedIcon from "@mui/icons-material/Cached";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { VERSION } from "../global/constants";
import { clearListings } from "../features/listings/listingsSlice";
import { clearSession } from "../features/session/sessionSlice";

export default function Footer(): JSX.Element {
   const session: SessionState = useAppSelector((state: any) => state.session);
   const dispatch = useAppDispatch();
   const handleRefesh = () => {
      try {
         // clear all redux and localStorate
         localStorage.clear();
         dispatch(clearListings());
         dispatch(clearSession());
      } catch (error) {
         console.error("Error in Footer.tsx: ", error);
      }
   };
   return (
      <Box
         height={160}
         gap={4}
         p={2}
         sx={{
            borderTop: "2px solid grey",
            backgroundColor: session.darkMode ? "#000" : "#999",
         }}
      >
         Ver: {VERSION}
         <Button onClick={() => handleRefesh()}>
            <CachedIcon />
         </Button>
      </Box>
   );
}
