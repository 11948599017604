import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { CompaniesState } from "./companies";

const initialState: CompaniesState = {
   arr: [],
   init: false,
};

export const companiesSlice = createSlice({
   name: "companies",
   initialState,
   // The `reducers` field lets us define reducers and generate associated actions
   reducers: {
      setCompanies: (state, action: PayloadAction<CompaniesState>) => {
         // Clear the state before setting new companies
         state.arr.splice(0, state.arr.length);
         // Push each new company into the state
         action.payload.arr.forEach((company) => {
            state.arr.push(company);
         });
         // Set the init flag to true
         state.init = true;
      },
      updateSingleCompany: (
         state: any,
         action: PayloadAction<{
            id: string | number;
            updateFields: string[];
            updateValues: string[];
         }>
      ) => {
         // given that you are passed the id, updateFields[] and updateValues[]
         // find the company with the id and update the fields
         const { id, updateFields, updateValues } = action.payload;
         const index = state.arr.findIndex(
            (company: { id: string | number }) => company.id === id
         );
         if (index !== -1) {
            updateFields.forEach((field: any, i: any) => {
               state.arr[index][field] = updateValues[i];
            });
         }
      },
      addSingleCompany: (state, action: PayloadAction<CompaniesState>) => {
         // Add a single company
         state.arr.push(action.payload.arr[0]);
      },
      clearCompanies: (state) => {
         // Clear the state
         state.arr.splice(0, state.arr.length);
         state.init = false;
      },
   },
});

export const {
   setCompanies,
   clearCompanies,
   updateSingleCompany,
   addSingleCompany,
} = companiesSlice.actions;
export const selectCompanies = (state: RootState) => state.companies;

export default companiesSlice.reducer;
