import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { SessionState } from "./session";

const initialState: SessionState = {
   lang: "en",
   notRobot: false,
   cookieConsent: true,
   darkMode: false,
   priceRange: [0, 1200000],
   sizeRange: [0, 1000],
   rooms: 0, // 0: All, 1: 1+, 2: 2+, 3: 3+, 4: 4+
   baths: 0, // 0: All, 1: 1+, 2: 2+, 3: 3+, 4: 4+
   region: "all",
   propertyType: 0, //0:All, 1: Apartamento, 2: Casa, 3: finca 4: Terreno
   propertyStatus: 0, // 0: All, 1: For Sale, 2: For Rent
   searchTerm: "",
   sortOrder: 1, // 1: Newest, 2: Oldest, 3: Highest Price First, 4: Lowest Price Frist
   subdivision: "",
   favoriteListings: [],
};

const sessionSlice = createSlice({
   name: "session",
   initialState,
   reducers: {
      setSession: (state, action: PayloadAction<Partial<SessionState>>) => {
         return { ...state, ...action.payload };
      },
      clearSession: (state) => {
         return { ...initialState };
      },
      setLangSession: (state, action: PayloadAction<string>) => {
         state.lang = action.payload;
      },
      setPriceRange: (state, action: PayloadAction<number[]>) => {
         state.priceRange = action.payload;
      },
      setSizeRange: (state, action: PayloadAction<number[]>) => {
         state.sizeRange = action.payload;
      },
      setRooms: (state, action: PayloadAction<number>) => {
         state.rooms = action.payload;
      },
      setBaths: (state, action: PayloadAction<number>) => {
         state.baths = action.payload;
      },
      setRegion: (state, action: PayloadAction<string>) => {
         state.region = action.payload;
      },
      setPropertyType: (state, action: PayloadAction<number>) => {
         state.propertyType = action.payload;
      },
      setPropertyStatus: (state, action: PayloadAction<number>) => {
         state.propertyStatus = action.payload;
      },
      setToggleDarkMode: (state) => {
         state.darkMode = !state.darkMode;
      },
      setToggleLang: (state) => {
         state.lang = state.lang === "en" ? "es" : "en";
      },
      setSortOrder: (state, action: PayloadAction<number>) => {
         state.sortOrder = action.payload;
      },
      setSubdivision: (state, action: PayloadAction<string>) => {
         state.subdivision = action.payload;
      },
      setSearchTerm: (state, action: PayloadAction<string>) => {
         state.searchTerm = action.payload;
      },
      setFavoriteListings: (state, action: PayloadAction<number[]>) => {
         state.favoriteListings = action.payload;
      },
   },
});

export const {
   setSession,
   clearSession,
   setLangSession,
   setPriceRange,
   setSizeRange,
   setRooms,
   setBaths,
   setRegion,
   setPropertyType,
   setPropertyStatus,
   setToggleDarkMode,
   setToggleLang,
   setSortOrder,
   setSubdivision,
   setSearchTerm,
   setFavoriteListings,
} = sessionSlice.actions;

export const selectSession = (state: RootState) => state.session;

export default sessionSlice.reducer;
