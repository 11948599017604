import { useEffect, useState } from "react";

import {
   Table,
   TableBody,
   TableCell,
   TableContainer,
   TablePagination,
   TableRow,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { Listing, ListingsState } from "../../features/listings/listings";
import { useAppSelector } from "../../app/hooks";
import { PropertiesListBoxMap } from "./PropertiesListBoxMap";
import { SessionState } from "../../features/session/session";

interface PropertiesListMapProps {
   listingsArr?: Listing[];
   mapBounds: google.maps.LatLngBounds | null;
   imgHeight?: number | string;
   imgWidth?: number | string;
}

export default function PropertiesListMap(
   props: PropertiesListMapProps
): JSX.Element {
   const { mapBounds } = props;
   const session: SessionState = useAppSelector((state) => state.session);
   const listings: ListingsState = useAppSelector((state) => state.listings);
   const listingsArr =
      props.listingsArr && props.listingsArr.length > 0
         ? props.listingsArr
         : listings.arr;

   // Pagination state
   const [page, setPage] = useState(0);
   const [rowsPerPage, setRowsPerPage] = useState(6);

   // Change handler for pagination
   const handleChangePage = (event: any, newPage: any) => {
      event.preventDefault();
      setPage(newPage);
   };

   // Change handler for rows per page
   const handleChangeRowsPerPage = (event: any) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
   };
   const handleChangeSearch = (event: any) => {
      // setSearchTerm(event.target.value);
      setPage(0);
   };
   useEffect(() => {
      console.log("mapBounds changed");
      setPage(0);
   }, [mapBounds, listingsArr]);

   return (
      <>
         {/* Table container */}
         <TableContainer>
            <Table>
               <TableBody>
                  {/* Render listings for the current page */}
                  {listingsArr &&
                     listingsArr
                        .slice(
                           page * rowsPerPage,
                           page * rowsPerPage + rowsPerPage
                        )
                        .reduce((acc: any[], _, index, arr) => {
                           if (index % 2 === 0)
                              acc.push(arr.slice(index, index + 2));
                           return acc;
                        }, [])
                        .map((pair: any[], index: number) => (
                           <TableRow key={index}>
                              <TableCell>
                                 <PropertiesListBoxMap
                                    row={pair[0]}
                                    session={session}
                                    imgHeight={props.imgHeight}
                                    imgWidth={props.imgWidth}
                                 />
                              </TableCell>
                              {pair[1] ? (
                                 <TableCell>
                                    <PropertiesListBoxMap
                                       row={pair[1]}
                                       session={session}
                                       imgHeight={props.imgHeight}
                                       imgWidth={props.imgWidth}
                                    />
                                 </TableCell>
                              ) : (
                                 <TableCell />
                              )}
                           </TableRow>
                        ))}
               </TableBody>
            </Table>
         </TableContainer>
         {/* Pagination controls */}
         <TablePagination
            rowsPerPageOptions={[3, 6, 12]}
            component='div'
            count={listingsArr.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
         />
      </>
   );
}
