import React from "react";
import { Listing } from "../../../features/listings/listings";
import { SessionState } from "../../../features/session/session";
import { Grid } from "@mui/material";

interface DetailsProps {
   listing: Listing;
   session: SessionState;
}

export default function ListingAmenities(props: DetailsProps): JSX.Element {
   const { listing, session } = props;
   const ENG = session.lang === "en" ? true : false;

   const amenities = [];

   return (
      <>
         <Grid container spacing={2}>
            {listing.lot_features.length > 3 && (
               <Grid item xs={12} sm={6}>
                  <h3>{ENG ? "Lot Features" : "Características del lote"}</h3>
                  <p>{listing.lot_features}</p>
               </Grid>
            )}
            {listing.interior_features.length > 3 && (
               <Grid item xs={12} sm={6}>
                  <h3>
                     {ENG ? "Interior Features" : "Características interiores"}
                  </h3>
                  <p>{listing.interior_features}</p>
               </Grid>
            )}
            {listing.exterior_features.length > 3 && (
               <Grid item xs={12} sm={6}>
                  <h3>
                     {ENG ? "Exterior Features" : "Características exteriores"}
                  </h3>
                  <p>{listing.exterior_features}</p>
               </Grid>
            )}
            {listing.other_services.length > 3 && (
               <Grid item xs={12} sm={6}>
                  <h3>{ENG ? "Other Services" : "Otros servicios"}</h3>
                  <p>{listing.other_services.length}</p>
               </Grid>
            )}
         </Grid>
      </>
   );
}
