import { Listing } from "../features/listings/listings";
import { SessionState } from "../features/session/session";

export const searchFilters = (
   session: SessionState,
   listingsArr: Listing[]
) => {
   const {
      priceRange,
      sizeRange,
      rooms,
      baths,
      region,
      propertyStatus,
      propertyType,
   } = session;

   try {
      if (session.subdivision !== "")
         listingsArr = filteredListingsSubdivision(
            listingsArr,
            session.subdivision
         );

      // filter by price_current where price currernt is not NULL
      listingsArr = listingsArr
         .filter((listing: Listing) => listing.price_current !== null)
         .filter((listing: Listing) => {
            if (priceRange[1] >= 1200000) {
               return (
                  parseFloat(String(listing.price_current)) >= priceRange[0]
               );
            }
            return (
               parseFloat(String(listing.price_current)) >= priceRange[0] &&
               parseFloat(String(listing.price_current)) <= priceRange[1]
            );
         });

      // filter by sqft_total where sqft_total is not NULL
      listingsArr = listingsArr
         .filter(
            (listing: Listing) =>
               listing.sqft_total !== null &&
               listing.sqft_total.toString() !== ""
         )
         .filter((listing: Listing) => {
            if (sizeRange[1] === 1000) {
               return listing.sqft_total >= sizeRange[0];
            }
            return (
               listing.sqft_total >= sizeRange[0] &&
               listing.sqft_total <= sizeRange[1]
            );
         });

      // filter property status
      console.log("Property Status: ", propertyStatus);
      if (propertyStatus === 1) {
         listingsArr = listingsArr.filter(
            (listing: Listing) => listing.price_current > 8000
         );
      } else if (propertyStatus === 2) {
         listingsArr = listingsArr.filter(
            (listing: Listing) =>
               listing.price_current < 8000 && listing.price_current > 0
         );
      }
      // filter by property type
      if (propertyType !== 0) {
         // 0:All, 1: Apartamento, 2: Casa, 3: finca 4: Terreno
         // Do as switch case for propertyType based on session.propertyType and listingArr.property_type
         switch (propertyType) {
            case 1:
               listingsArr = listingsArr.filter((listing: Listing) =>
                  listing.property_type.toLowerCase().includes("apartamento")
               );
               break;
            case 2:
               listingsArr = listingsArr.filter((listing: Listing) =>
                  listing.property_type.toLowerCase().includes("casa")
               );
               break;
            case 3:
               listingsArr = listingsArr.filter((listing: Listing) =>
                  listing.property_type.toLowerCase().includes("finca")
               );
               break;
            case 4:
               listingsArr = listingsArr.filter((listing: Listing) =>
                  listing.property_type.toLowerCase().includes("terreno")
               );
               break;
            default:
               break;
         }
      }

      if (rooms !== 0) {
         listingsArr = listingsArr.filter(
            (listing: Listing) => listing.bedrooms >= rooms
         );
      }
      if (baths !== 0) {
         listingsArr = listingsArr.filter(
            (listing: Listing) => listing.bathrooms >= baths
         );
      }

      // set up a switch case for session.order
      switch (session.sortOrder) {
         case 1:
            listingsArr = sortNewestFirst(listingsArr);
            break;
         case 2:
            listingsArr = sortOldestFirst(listingsArr);
            break;
         case 3:
            listingsArr = sortPriceHighToLow(listingsArr);
            break;
         case 4:
            listingsArr = sortPriceLowToHigh(listingsArr);
            break;
         default:
            listingsArr = sortNewestFirst(listingsArr);
            break;
      }
   } catch (error) {
      console.error("Error in PropertiesIndex: ", error);
   }
   return listingsArr;
};

export const filteredListingsSubdivision = (rows: any, subdivision: any) => {
   try {
      // if subdivision is empty, return all rows
      if (!subdivision || subdivision === "") return rows;

      // if subdivision === "land" return all rows with property_type === "Terreno" case insensitive
      if (subdivision.toLowerCase() === "land") {
         return rows.filter((listing: any) =>
            listing.property_type.toLowerCase().includes("terreno")
         );
      }

      // if subdivision contains the word "beach" make it worth "playa"
      if (subdivision.toLowerCase().includes("beach")) {
         subdivision = subdivision.replace("beach", "playa");
      }
      // replace "-" with " " and convert to string
      subdivision = subdivision.replace(/-/g, " ");
      return rows.filter((listing: any) =>
         listing.subdivision
            ? listing.subdivision
                 .toLowerCase()
                 .includes(subdivision.toLowerCase())
            : ""
      );
   } catch (error) {
      console.error("Error in filteredListingsSubdivision: ", error);
      return [];
   }
};

export const sortNewestFirst = (rows: any) => {
   try {
      return rows
         .filter(
            (row: any) => row.date_listed !== null && row.date_listed !== ""
         )
         .sort((a: any, b: any) => {
            const dateANumeric = Number(a.date_listed.replace(/-/g, ""));
            const dateBNumeric = Number(b.date_listed.replace(/-/g, ""));
            return dateBNumeric - dateANumeric;
         });
   } catch (error) {
      console.error("Error in sortNewestFirst: ", error);
      return [];
   }
};

export const sortOldestFirst = (rows: any) => {
   try {
      return rows
         .filter(
            (row: any) => row.date_listed !== null && row.date_listed !== ""
         )
         .sort((a: any, b: any) => {
            const dateANumeric = Number(a.date_listed.replace(/-/g, ""));
            const dateBNumeric = Number(b.date_listed.replace(/-/g, ""));
            return dateANumeric - dateBNumeric;
         });
   } catch (error) {
      console.error("Error in sortOldestFirst: ", error);
      return [];
   }
};

export const sortPriceLowToHigh = (rows: any) => {
   try {
      return rows
         .filter(
            (row: any) => row.price_current !== null && row.price_current !== ""
         )
         .sort((a: any, b: any) => a.price_current - b.price_current);
   } catch (error) {
      console.error("Error in sortPriceLowToHigh: ", error);
      return [];
   }
};

export const sortPriceHighToLow = (rows: any) => {
   try {
      return rows
         .filter(
            (row: any) => row.price_current !== null && row.price_current !== ""
         )
         .sort((a: any, b: any) => b.price_current - a.price_current);
   } catch (error) {
      console.error("Error in sortPriceHighToLow: ", error);
      return [];
   }
};
