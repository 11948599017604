import React from "react";
import Slider from "./Slider";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import Loading from "./Loading";
import { apiPost } from "../../global/ApiRequest";
import { API_URL } from "../../global/constants";
import { returnDateToday } from "../../global/utils";
import { ListingsState } from "../../features/listings/listings";
import { setListings } from "../../features/listings/listingsSlice";

export default function HeroLeft(): JSX.Element {
   const listings: ListingsState = useAppSelector(
      (state: any) => state.listings
   );
   const dispatch = useAppDispatch();
   if (listings.lastUpdate !== returnDateToday() || !listings.init) {
      (async () => {
         try {
            const data: any = await apiPost(API_URL + "/getListings", {
               limit: 2000,
            });
            //console.log(data.data.listings);
            dispatch(setListings({ ...listings, arr: data.data.listings }));
         } catch (error) {
            console.error("Error fetching listings: ", error);
         }
      })();
   }

   return (
      <div
         style={{
            backgroundColor: "rgba(222, 222, 222, 0.6)",

            borderRadius: 4,
            padding: 20,
            width: 370,
            minHeight: 310,
            maxHeight: "100%",
            margin: 5,
         }}
      >
         {listings.init ? <Slider /> : <Loading msg='Loading listings...' />}
      </div>
   );
}
