import React from "react";
import { SessionState } from "../../features/session/session";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import Layout from "../../components/Layout";
import { useNavigate, useParams } from "react-router-dom";
import ListIcon from "@mui/icons-material/List";
import RoomIcon from "@mui/icons-material/Room";
import {
   Button,
   ButtonGroup,
   FormControl,
   Grid,
   InputLabel,
   MenuItem,
   Select,
   Tooltip,
} from "@mui/material";
import LayoutInnerEnclosed from "../../components/LayoutInnerEnclosed";
import PropertiesListView from "./PropertiesListView";

import {
   setSortOrder,
   setSubdivision,
} from "../../features/session/sessionSlice";
import { useDispatch } from "react-redux";
import { Listing } from "../../features/listings/listings";
import { searchFilters } from "../../global/searchFilters";

export default function PropertiesIndex(): JSX.Element {
   let { sub } = useParams();
   const dispatch = useAppDispatch();
   const navigate = useNavigate();
   const session: SessionState = useAppSelector((state: any) => state.session);

   const listings = useAppSelector((state: any) => state.listings);

   if (sub) {
      if (sub.toString().includes("balboa")) sub = "Balboa";
      dispatch(setSubdivision(sub));
   } else {
      dispatch(setSubdivision(""));
   }

   const ENG = session.lang === "en" ? true : false;

   const handleSort = (event: any) => {
      dispatch(setSortOrder(event.target.value));
      console.log(event.target.value);
   };
   let listingsArr = searchFilters(session, listings.arr);

   return (
      <Layout>
         <LayoutInnerEnclosed>
            <h3>
               {session.lang === "en"
                  ? "Properties Listing"
                  : "Listado de Propiedades"}
            </h3>
            <Grid container spacing={1}>
               <Grid item xs={12} md={4} style={{ marginTop: 20 }}>
                  {"Showing " + listingsArr.length + " properties"}
               </Grid>

               <Grid item xs={12} md={4}>
                  <FormControl>
                     <InputLabel id='sort' size='small' sx={{ width: 120 }}>
                        {ENG ? "Sort By" : "Ordenar por"}
                     </InputLabel>
                     <Select
                        size='small'
                        defaultValue={1}
                        label={ENG ? "Sort By" : "Ordenar por"}
                        onChange={handleSort}
                        sx={{ width: 140 }}
                     >
                        <MenuItem value={1}>
                           {ENG ? "Newest" : "El más nuevo"}
                        </MenuItem>
                        <MenuItem value={2}>
                           {ENG ? "Oldest" : "Más antiguo"}
                        </MenuItem>
                        <MenuItem value={3}>
                           {ENG ? "High to Low Price" : "Precio alto a bajo"}
                        </MenuItem>
                        <MenuItem value={4}>
                           {ENG ? "Low to High Price" : "Precio bajo a alto"}
                        </MenuItem>
                     </Select>
                  </FormControl>
               </Grid>
               <Grid item xs={12} sm={4}>
                  <ButtonGroup variant='text'>
                     <Tooltip title={ENG ? "List View" : "Vista de Lista"}>
                        <Button size='small'>
                           <ListIcon />
                        </Button>
                     </Tooltip>
                     <Tooltip title={ENG ? "Map View" : "Vista de Cuadricula"}>
                        <Button size='small' onClick={() => navigate(`/map`)}>
                           <RoomIcon />
                        </Button>
                     </Tooltip>
                  </ButtonGroup>
               </Grid>
            </Grid>
            <PropertiesListView listingsArr={listingsArr} />
         </LayoutInnerEnclosed>
      </Layout>
   );
}
