import { Grid } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";
import { SessionState } from "../../features/session/session";
import { PRIMARY_COLOR } from "../../global/constants";

// component to display the graphic chips
const Chip = (props: { name: string; img: string }) => {
   const navigate = useNavigate();
   const handleClick = (img: string) => {
      let tmp = img.split(".");
      navigate("/properties/" + tmp[0]);
   };
   const { name, img } = props;

   return (
      <div
         style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundImage: `url('/assets/img/chips/${img}')`,
            backgroundSize: "cover",
            borderRadius: "5px",
            width: "290px",
            height: "200px",
            cursor: "pointer",
         }}
      >
         <span
            onClick={() => handleClick(img)}
            style={{
               background: "rgba(255, 255, 255, 0.7)",
               padding: "7px",
               color: PRIMARY_COLOR,
               borderRadius: "13px",
            }}
         >
            {name}
         </span>
      </div>
   );
};

export default function GraphicChips(): JSX.Element {
   const session: SessionState = useAppSelector((state: any) => state.session);
   const images =
      session.lang === "en"
         ? [
              { name: "Properties in San Francisco", img: "san-francisco.png" },
              { name: "Properties on Balboa Avenue", img: "balboa-ave.png" },
              {
                 name: "Properties in Costa del Este",
                 img: "costa-del-este.png",
              },
              { name: "Properties in Boquete", img: "boquete.png" },
              { name: "Properties in Coronado", img: "coronado.png" },
              { name: "Properties in El Valle", img: "el-valle.png" },
              { name: "Properties at Beach", img: "beach.png" },
              { name: "Land and Lots", img: "land.png" },
           ]
         : [
              {
                 name: "Propiedades en San Francisco",
                 img: "san-francisco.png",
              },
              { name: "Propiedades en Avenida Balboa", img: "balboa-ave.png" },
              {
                 name: "Propiedades en Costa del Este",
                 img: "costa-del-este.png",
              },
              { name: "Propiedades en Boquete", img: "boquete.png" },
              { name: "Propiedades en Coronado", img: "coronado.png" },
              { name: "Propiedades en El Valle", img: "el-valle.png" },
              { name: "Propiedades en la Playa", img: "beach.png" },
              { name: "Terrenos y Lotes", img: "land.png" },
           ];

   return (
      <>
         <Grid
            container
            spacing={2}
            style={{ maxWidth: "900px", margin: "0 auto" }}
         >
            <Grid
               item
               xs={12}
               sm={6}
               md={4}
               style={{
                  display: "flex",
                  justifyContent: "center",
               }}
               key={"i1"}
            >
               <div style={{ padding: 10, marginTop: 40 }}>
                  {session.lang === "en"
                     ? "Explore our selection of properties located in Panama City's most prestigious neighborhoods, as well as beautiful beachside and countryside locations."
                     : "Explore nuestra selección de propiedades ubicadas en los vecindarios más prestigiosos de la Ciudad de Panamá, así como en hermosas ubicaciones junto al mar y en el campo."}
               </div>
            </Grid>
            {images.map((img, index) => (
               <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  style={{ display: "flex", justifyContent: "center" }}
                  key={index}
               >
                  <Chip name={img.name} img={img.img} />
               </Grid>
            ))}
         </Grid>
      </>
   );
}
