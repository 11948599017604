import { createTheme } from "@mui/material/styles";

export const darkTheme = createTheme({
   palette: {
      mode: "dark",
      primary: {
         main: "#4172d8",
         light: "#5a87e6",
         dark: "#215ad2",
      },
      secondary: {
         main: "#f50057",
         light: "#f33276",
         dark: "#ad023e",
         contrastText: "#ffffff",
      },
   },
});

export const lightTheme = createTheme({
   palette: {
      primary: {
         light: "##3a86ae",
         main: "#0B3F63",
         dark: "#062943",
         contrastText: "#fff",
      },
      secondary: {
         light: "#c4916d",
         main: "#AF7952",
         dark: "#9a6238",
         contrastText: "#000",
      },
   },
});
