import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { Agent, AgentsState } from "./agents";

const initialState: AgentsState = {
   arr: [],
   init: false,
};

export const agentsSlice = createSlice({
   name: "agents",
   initialState,
   // The `reducers` field lets us define reducers and generate associated actions
   reducers: {
      setAgents: (state, action: PayloadAction<AgentsState>) => {
         // Clear the state before setting new agents
         state.arr.splice(0, state.arr.length);
         // Push each new agent into the state
         action.payload.arr.forEach((agent) => {
            state.arr.push(agent);
         });
         // Set the init flag to true
         state.init = true;
      },
      updateSingleAgent: (
         state: any,
         action: PayloadAction<{
            id: string | number;
            updateFields: string[];
            updateValues: string[];
         }>
      ) => {
         // given that you are passed the id, updateFields[] and updateValues[]
         // find the agent with the id and update the fields
         const { id, updateFields, updateValues } = action.payload;
         const index = state.arr.findIndex((agent: Agent) => agent.id === id);
         if (index !== -1) {
            updateFields.forEach((field: any, i: any) => {
               state.arr[index][field] = updateValues[i];
            });
         }
      },
      addSingleAgent: (state, action: PayloadAction<Agent>) => {
         // Add a single agent to the state
         state.arr.push(action.payload);
      },
      clearAgents: (state) => {
         // Clear the state
         state.arr.splice(0, state.arr.length);
         state.init = false;
      },
   },
});

export const { setAgents, updateSingleAgent, clearAgents, addSingleAgent } =
   agentsSlice.actions;
export const selectAgents = (state: RootState) => state.agents;

export default agentsSlice.reducer;
