import {
   Button,
   ButtonGroup,
   Grid,
   Paper,
   Tooltip,
   Typography,
} from "@mui/material";

import LocationOnIcon from "@mui/icons-material/LocationOn";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Listing } from "../../features/listings/listings";
import { IMG_PATH } from "../../global/constants";
import { generateAddressUrl } from "../../global/utils";
import { SessionState } from "../../features/session/session";
import { useNavigate } from "react-router-dom";

export const PropertiesListBoxMap = (props: {
   row: Listing;
   session: SessionState;
   imgHeight?: number | string;
   imgWidth?: number | string;
}) => {
   let listing = props.row;
   const { imgHeight = 270, imgWidth = 450 } = props;
   const session = props.session;
   const ENG = session.lang === "en" ? true : false;

   const navigate = useNavigate();

   const price = listing.price_current
      ? new Intl.NumberFormat("en-US", {
           style: "currency",
           currency: "USD",
        }).format(Number(listing.price_current))
      : "";

   const handleShowListing = () => {
      const addressUrl = generateAddressUrl(listing);
      window.open(`/listing/${addressUrl}`, "_blank");
      // navigate(`/listing/${addressUrl}`);
   };

   const handleMapClick = (lat: number, lng: number) => {
      // Open a new window with Google Maps
      const newWindow: any = window.open(
         `https://www.google.com/maps?q=${lat},${lng}`,
         "_blank"
      );
      if (newWindow) {
         // Add a marker to the map
         newWindow.addEventListener("load", () => {
            const marker = newWindow.google.maps.Marker({
               position: { lat: lng },
               map: newWindow.map,
               title: "Marker",
            });
         });
      } else {
         // Handle popup blocker
         alert("Please allow popups to see the map.");
      }
   };

   let imagesArr = listing.images
      ? listing.images.includes(",")
         ? listing.images.split(",")
         : [listing.images]
      : ["noImage.png"];

   try {
      if (
         listing &&
         (!listing.unique_id ||
            listing.unique_id === "" ||
            listing.unique_id === undefined ||
            listing.unique_id === null)
      ) {
         listing.unique_id = "000000NA";
      }
   } catch (error) {
      console.log("Error in ListingBox.tsx: ", error);
   }

   const saleText = ENG ? "FOR SALE" : "EN VENTA";
   const rentalText = ENG ? "RENTAL" : "ALQUILER";

   return (
      <Paper
         sx={{
            p: 1,
            margin: "auto",
            flexGrow: 1,
            backgroundColor: (theme) =>
               theme.palette.mode === "dark" ? "#1A2027" : "#fff",
         }}
      >
         <Grid container spacing={2}>
            <Grid item xs={12} md={12} style={{ position: "relative" }}>
               <div
                  style={{
                     position: "absolute",
                     top: 10,
                     left: 10,
                     padding: 4,
                     margin: 6,
                     color: "#FFF",
                     backgroundColor: "rgba(0, 0, 0, 0.5)",
                  }}
               >
                  <Typography
                     variant='body2'
                     color='text.secondary'
                     component={"span"}
                     style={{
                        backgroundColor:
                           listing.price_current &&
                           parseInt(String(listing.price_current)) > 5000
                              ? "#F00"
                              : "#b57610",
                        color: "#FFF",
                        padding: "4px",
                        marginRight: 8,
                     }}
                  >
                     {listing.price_current &&
                     parseInt(String(listing.price_current)) > 5000
                        ? saleText
                        : rentalText}
                  </Typography>
                  <Typography variant='h6' component='span'>
                     {price}
                  </Typography>
               </div>
               <div
                  style={{
                     position: "absolute",
                     bottom: 10,
                     left: 10,
                     padding: 4,
                     marginLeft: 6,
                     marginBottom: -5,
                     color: "#FFF",
                     backgroundColor: "rgba(0, 0, 0, 0.5)",
                  }}
               >
                  {listing.map_area}
                  <ButtonGroup variant='text'>
                     <Tooltip title='Location'>
                        <Button
                           style={{ marginTop: 1, color: "#FFF" }}
                           size='small'
                           onClick={() =>
                              handleMapClick(
                                 parseFloat(listing.latitude),
                                 parseFloat(listing.longitude)
                              )
                           }
                        >
                           <LocationOnIcon />
                        </Button>
                     </Tooltip>
                     <Tooltip title={ENG ? "View Listing" : "Ver Propiedad"}>
                        <Button
                           style={{ marginTop: 1, color: "#FFF" }}
                           size='small'
                           onClick={() => handleShowListing()}
                        >
                           <VisibilityIcon />
                        </Button>
                     </Tooltip>
                  </ButtonGroup>
               </div>
               <img
                  src={IMG_PATH + `/` + imagesArr[0]}
                  alt='Property'
                  style={{
                     width: imgWidth,
                     height: imgHeight,
                     objectFit: "cover",
                  }}
               />
            </Grid>
         </Grid>
      </Paper>
   );
};
