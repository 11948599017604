import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import "./styles.css";

// import required modules
import { Keyboard, Pagination, Navigation } from "swiper/modules";
import { ListingsState } from "../../features/listings/listings";
import { useAppSelector } from "../../app/hooks";
import ListingCard from "./ListingCard";
import { SessionState } from "../../features/session/session";

export default function Slider() {
   const listings: ListingsState = useAppSelector(
      (state: any) => state.listings
   );
   const session: SessionState = useAppSelector((state: any) => state.session);
   return (
      <>
         <Swiper
            slidesPerView={1}
            // spaceBetween={30}
            pagination={{
               type: "progressbar",
            }}
            keyboard={{
               enabled: true,
            }}
            // style={{ width: "310px", height: 400 }} // Fixing the width at 310 pixels

            navigation={true}
            modules={[Keyboard, Pagination, Navigation]}
         >
            {listings.init &&
               listings.arr
                  .filter(
                     (listing) =>
                        listing.subdivision &&
                        listing.subdivision.includes("Barco")
                  )
                  .map((listing) => (
                     <SwiperSlide key={listing.id}>
                        <ListingCard listing={listing} session={session} />
                     </SwiperSlide>
                  ))}
         </Swiper>
      </>
   );
}
