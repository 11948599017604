import React from "react";
import { useParams } from "react-router-dom";
import Layout from "../../components/Layout";
import { ListingsState } from "../../features/listings/listings";
import { useAppSelector } from "../../app/hooks";
import DisplayListing from "./DisplayListing";
import LayoutInnerEnclosed from "../../components/LayoutInnerEnclosed";

export default function Listing(): JSX.Element {
   const { id } = useParams();
   const listings: ListingsState = useAppSelector(
      (state: any) => state.listings
   );
   //   console.log(listings);
   const [_, suffix] =
      id !== undefined && id.toString().includes("-mls")
         ? id.split("-mls")
         : ["", ""];

   let listing: any = "";
   try {
      listing = listings.arr.find((l: any) => l.id === parseInt(suffix));
   } catch (error) {
      console.log("Error in ListingIndex.tsx: ", error);
   }

   return (
      <Layout>
         <LayoutInnerEnclosed>
            {listing ? (
               <DisplayListing listing={listing} />
            ) : (
               <>
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <center>
                     Listing not found
                     <br />
                     {id}
                  </center>
               </>
            )}
         </LayoutInnerEnclosed>
      </Layout>
   );
}
