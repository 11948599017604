import React, { CSSProperties } from "react";
import Layout from "../../components/Layout";
import Grid from "@mui/material/Grid";
import SearchBox from "./HeroRight";
import HeroLeft from "./HeroLeft";
import HeroRight from "./HeroRight";
import GraphicChips from "./GraphicChips";
import { LAYOUT_WIDTH } from "../../global/constants";

const HomeIndex: React.FC = () => {
   return (
      <Layout>
         <div
            style={{
               display: "flex",
               flexDirection: "column",
               alignItems: "center",
               backgroundImage: `url("../../assets/img/bg1.png")`,
               backgroundSize: "cover", // Ensure the image covers the container
               backgroundRepeat: "no-repeat", // Prevent the image from repeating
               paddingTop: 20,
               paddingBottom: 20,
            }}
         >
            <Grid container spacing={2} sx={{ maxWidth: LAYOUT_WIDTH }}>
               <Grid
                  item
                  xs={12}
                  md={6}
                  style={{ display: "flex", justifyContent: "center" }}
               >
                  <HeroLeft />
               </Grid>
               <Grid
                  item
                  xs={12}
                  md={6}
                  style={{ display: "flex", justifyContent: "center" }}
               >
                  <HeroRight />
               </Grid>
            </Grid>
         </div>
         <GraphicChips />
      </Layout>
   );
};

export default HomeIndex;
