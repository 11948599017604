import * as React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

export default function Loading(props: { msg?: string }) {
   const { msg = "loading" } = props;
   return (
      <div
         style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%", // Full height of the viewport
            width: "100%", // Full width of the viewport
            backgroundColor: "rgba(222,222,222, 0.5)", // Semi-transparent white
            borderRadius: 4,
         }}
      >
         <CircularProgress />
      </div>
   );
}
