import React from "react";
import { Listing } from "../../features/listings/listings";
import { IMG_PATH } from "../../global/constants";
import { useAppDispatch } from "../../app/hooks";
import Button from "@mui/material/Button";
import { SessionState } from "../../features/session/session";
import { generateAddressUrl, getAddress } from "../../global/utils";
import { useNavigate } from "react-router-dom";

interface MarkerPopupProps {
   listing: Listing;
   session: SessionState;
   height?: number;
}

export default function ListingCard(props: MarkerPopupProps) {
   const { listing, session, height = 260 } = props;
   const navigate = useNavigate();

   let address = "";

   const handleView = () => {
      const addressUrl = generateAddressUrl(listing);
      navigate(`/listing/${addressUrl}`);
   };

   address = getAddress(listing);
   let image = "/assets/img/noImage.png";
   console.log(listing);
   if (
      listing.images !== undefined &&
      listing.images !== null &&
      listing.images.includes(",")
   ) {
      const images = listing.images.split(",");
      image = IMG_PATH + "/" + images[0];
   }
   const specs = `Rooms:${listing.bedrooms || 0} Bathroom:${
      listing.bathrooms || 0
   } ft² ${listing.sqft_total || 0}`;

   const buyPrice =
      listing.price_current &&
      listing.price_current > listing.price_current_rent
         ? new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
           }).format(Number(listing.price_current))
         : "NA";
   const rentPrice = listing.price_current_rent
      ? new Intl.NumberFormat("en-US", {
           style: "currency",
           currency: "USD",
        }).format(Number(listing.price_current_rent))
      : "NA";

   return (
      <>
         <div>
            <img src={image} alt='tn' width='100%' height={height} />
         </div>
         <div>
            <strong>
               Buy:{buyPrice} - Rent:{rentPrice}
            </strong>
         </div>
         <div>{specs}</div>
         <div
            style={{
               marginBottom: 10,
            }}
         >
            {address}
         </div>

         <div style={{ marginTop: 30 }}>
            <Button variant='contained' fullWidth onClick={() => handleView()}>
               View Listing
            </Button>
         </div>
      </>
   );
}
