import { Button, TextField } from "@mui/material";
import React, { useRef } from "react";
import { SessionState } from "../features/session/session";
import { useAppSelector } from "../app/hooks";
import ReCAPTCHA from "react-google-recaptcha";
import { SITE_KEY } from "../global/constants";

interface ContactFormProps {
   defaultMsg?: string;
}

export const ContactForm = React.memo(
   (props: ContactFormProps): JSX.Element => {
      const session: SessionState = useAppSelector(
         (state: any) => state.session
      );
      const { defaultMsg = "" } = props;
      const recaptchaRef = React.createRef<ReCAPTCHA>();
      const captcha = useRef<boolean>(false);

      const onChange = () => (captcha.current = true);

      const handleSubmit = () => {
         try {
            console.log(captcha);
         } catch (error) {
            console.error("Error submitting form: ", error);
         }
      };

      return (
         <>
            <TextField
               size={"small"}
               id='name'
               fullWidth
               label={session.lang === "en" ? "Full Name" : "Nombre Completo"}
               variant='outlined'
               sx={{ mb: 0.5 }}
            />
            <TextField
               size={"small"}
               id='phone'
               fullWidth
               label={session.lang === "en" ? "Phone" : "Telephono"}
               variant='outlined'
               sx={{ mb: 0.7 }}
            />
            <TextField
               size={"small"}
               id='email'
               fullWidth
               label={session.lang === "en" ? "Email" : "Correo Electronico"}
               variant='outlined'
               sx={{ mb: 0.7 }}
            />
            <TextField
               size={"small"}
               key={session.lang}
               multiline
               rows={4}
               id='comments'
               fullWidth
               label={session.lang === "en" ? "Message" : "Mensaje"}
               defaultValue={defaultMsg}
               InputLabelProps={{ shrink: true }}
               variant='outlined'
               sx={{ mb: 0.7 }}
            />
            <div>
               <div
                  key={Math.random()}
                  className='captcha'
                  style={{
                     display: "flex", // Use flexbox
                     alignItems: "center", // Vertically center children
                     justifyContent: "center", // Horizontally center children
                     transformOrigin: "0 0",
                     marginBottom: 2,
                     marginTop: 2,
                  }}
               >
                  <ReCAPTCHA sitekey={SITE_KEY} onChange={onChange} />
               </div>
               <Button
                  fullWidth
                  size='small'
                  variant='contained'
                  sx={{ mt: 0.5 }}
                  onClick={() => handleSubmit()}
               >
                  {session.lang === "en" ? "Submit" : "Enviar"}
               </Button>
            </div>
         </>
      );
   }
);
