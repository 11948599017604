import React from "react";
import { formatCurrency } from "../../global/utils";
import { Tooltip } from "@mui/material";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import { setFavoriteListings } from "../../features/session/sessionSlice";

interface DisplayListingsTopRightProps {
   listing: any;
   session: any;
}

export default function DisplayListingTopRight(
   props: DisplayListingsTopRightProps
): JSX.Element {
   const { listing, session } = props;
   const ENG = session.lang === "en" ? true : false;

   const handleAddToFav = (id: number) => {
      console.log("Add to fav");
      // first check to see if the id already exists in the favoriteListings
      if (session.favoriteListings.includes(id)) {
         console.log("Already in favorites");
         return;
      }
      // push mls id to favoriteListings
      const newFavList = [...session.favoriteListings, id];
      console.log("New fav list: ", newFavList);
      setFavoriteListings(newFavList);
   };

   const handleMapView = (lat: number, lng: number) => {
      // Open a new window with Google Maps
      const newWindow: any = window.open(
         `https://www.google.com/maps?q=${lat},${lng}`,
         "_blank"
      );
      if (newWindow) {
         // Add a marker to the map
         newWindow.addEventListener("load", () => {
            const marker = newWindow.google.maps.Marker({
               position: { lat: lng },
               map: newWindow.map,
               title: "Marker",
            });
         });
      } else {
         // Handle popup blocker
         alert("Please allow popups to see the map.");
      }
   };

   const saleText = ENG ? "FOR SALE" : "EN VENTA";
   const rentalText = ENG ? "RENTAL" : "ALQUILER";

   return (
      <>
         <div
            style={{
               display: "flex",
               alignItems: "right",
               fontSize: "2em",
               fontWeight: "900",
            }}
         >
            {formatCurrency(
               listing.price_current
                  ? listing.price_current
                  : listing.price_current_rent
            )}
         </div>
         <div style={{ display: "flex", alignItems: "center" }}>
            <div
               style={{
                  display: "flex",
                  marginTop: 10,
                  alignItems: "center",
                  backgroundColor:
                     listing.price_current &&
                     parseInt(String(listing.price_current)) > 5000
                        ? "#F00"
                        : "#b57610",
                  justifyContent: "center",
                  width: 100,
                  textAlign: "center",
                  borderRadius: 4,
                  padding: 4,
                  color: "#fff",
               }}
            >
               {listing.price_current &&
               parseInt(String(listing.price_current)) > 5000
                  ? saleText
                  : rentalText}
            </div>
            <Tooltip title={ENG ? "Add to favorites" : "Añadir a lista"}>
               <FavoriteBorderIcon
                  onClick={() => handleAddToFav(listing.id)}
                  style={{ cursor: "pointer", marginLeft: 10 }}
               />
            </Tooltip>
            <Tooltip
               title={ENG ? "Map" : "Ver en el mapa"}
               style={{ marginLeft: 10 }}
            >
               <LocationOnOutlinedIcon
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                     handleMapView(listing.latitude, listing.longitude)
                  }
               />
            </Tooltip>
         </div>
      </>
   );
}
